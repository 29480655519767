import { Metric, onCLS, onFCP, onFID, onTTFB } from "web-vitals"
import { onINP, onLCP } from "web-vitals/attribution"

import { attributionTraits, track } from "../analytics"
import { isBrowser } from "../browser"
import { getUserCountryFromLanguage } from "../user-data"

export const webVitalsEvents = {
  CLS: "Web Vital: CLS",
  FCP: "Web Vital: FCP",
  FID: "Web Vital: FID",
  INP: "Web Vital: INP",
  LCP: "Web Vital: LCP",
  TTFB: "Web Vital: TTFB",
}

/**
 * Get comprehensive metadata to send with vitals
 */
function getMetadata() {
  if (!isBrowser()) return {}

  // Get attribution data
  const attribution = attributionTraits()

  // Build device and environment data
  return {
    // Environment
    environment: process.env.GATSBY_ENV || "development",
    deployId: process.env.VERCEL_GIT_COMMIT_SHA
      ? process.env.VERCEL_GIT_COMMIT_SHA.substring(0, 7)
      : undefined,
    deployBranch: process.env.VERCEL_GIT_COMMIT_REF,
    deployUrl: process.env.VERCEL_URL,

    // Device
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
    devicePixelRatio: window.devicePixelRatio,

    // Page
    path: window.location.pathname,
    hostname: window.location.hostname,

    // Locale
    country: getUserCountryFromLanguage(),
    language: navigator.language,

    ...attribution,
  }
}

/**
 * Report web vitals to Segment
 */
function reportWebVital(metric: Metric) {
  const eventName = webVitalsEvents[metric.name as keyof typeof webVitalsEvents]

  track(eventName, {
    // Standard metric data
    name: metric.name,
    delta: metric.delta,
    value: metric.value,
    id: metric.id,

    // All metadata
    ...getMetadata(),
  })
}

export function initWebVitals() {
  // standard metrics
  onCLS(reportWebVital)
  onFCP(reportWebVital)
  onTTFB(reportWebVital)
  onFID(reportWebVital) // legacy, but still useful alongside INP

  // metrics with attribution data
  onLCP(({ name, delta, value, id, attribution }) => {
    track(webVitalsEvents.LCP, {
      name,
      delta,
      value,
      id,
      ...getMetadata(),
      // Attribution data specific to LCP
      element: attribution.element,
      url: attribution.url,
      timeToFirstByte: attribution.timeToFirstByte,
      resourceLoadDelay: attribution.resourceLoadDelay,
      resourceLoadDuration: attribution.resourceLoadDuration,
      elementRenderDelay: attribution.elementRenderDelay,
    })
  })

  onINP(({ name, delta, value, id, attribution }) => {
    const attributionData = {
      loadState: attribution.loadState,
      inputDelay: attribution.inputDelay,
      processingDuration: attribution.processingDuration,
      presentationDelay: attribution.presentationDelay,
    }

    track(webVitalsEvents.INP, {
      name,
      delta,
      value,
      id,
      ...getMetadata(),
      // Attribution data specific to INP
      ...attributionData,
    })
  })
}
