import { isBrowser } from "../browser"

export const USER_INFO_KEY = "userInfo"

export const getUserEmail = (): string => {
  if (!isBrowser()) return ""

  const userInfo = localStorage.getItem(USER_INFO_KEY)
  if (userInfo) {
    return JSON.parse(userInfo).email || ""
  }
  const params = new URLSearchParams(isBrowser() ? window.location.search : "")
  return params.get("email") || ""
}

interface UserInfo {
  [key: string]: string
}

const parseStoredUserInfo = (): UserInfo => {
  try {
    const stored = localStorage.getItem(USER_INFO_KEY)
    return stored ? JSON.parse(stored) : {}
  } catch {
    return {}
  }
}

export const getLocalStorageUserInfo = (fieldName: string): string => {
  if (!isBrowser()) return ""
  return parseStoredUserInfo()[fieldName] || ""
}

export const setLocalStorageUserInfo = (newInfo: UserInfo): void => {
  if (!isBrowser()) return

  try {
    const existingInfo = parseStoredUserInfo()
    localStorage.setItem(
      USER_INFO_KEY,
      JSON.stringify({ ...existingInfo, ...newInfo }),
    )
  } catch {
    // If updating fails, just set the new info
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(newInfo))
  }
}

export const checkFormSubmission = (form: string): boolean => {
  if (!isBrowser()) return false

  return !!localStorage.getItem(form)
}

export const getUserCountryFromLanguage = (): string | undefined => {
  if (!isBrowser()) return ""
  const { language } = window.navigator
  const countryCode = new Intl.Locale(language).region
  return countryCode
}
