/** **** Event names ******** */
// Events should follow the syntax of "{app} : {object} : {action}"
// All event names should go here. This helps maintain our taxonomy.
// Do not add random magic strings representing events elsewhere.
//
// When choosing object and action, use common sense terms that a non-technical person
// can understand. Reading event names should *not* require knowledge of our internal code structure.
//
// ACTION REQUIRED IF EDITING:
// If you are adding an event name, please add a description in this schema doc:
// https://www.notion.so/tremendous/Schema-561f3193aea74ba7ba3b6b87e80d250e

export const eventNames = {
  buttonClicked: "marketing : button : click",
  searchQueryChanged: "marketing : search query : change",
  searchQueryBlurred: "marketing : search query : blur",
  searchQueryTriggered: "marketing : search query : triggered",
  clientTourRequested: "marketing : client tour : requested",
  leadCaptured: "dashboard : organization lead : created",
  itemClicked: "marketing : item : click",
  pageLoaded: "marketing : page : load",
  rewardRequested: "core : demo reward : requested",
  formSubmissionErrored: "marketing : form : submit : error",
  formSubmissionSucceeded: "marketing : form : submit : success",
  videoPlaybackStarted: "marketing : video : playback : started",
  videoContentStarted: "marketing : video : content : started",
  videoContentEnded: "marketing : video : content : ended",
  videoContentInterrupted: "marketing : video : content : interrupted",
} as const
