import { WrapPageElementBrowserArgs } from "gatsby"
import * as React from "react"

import { LocationState, PageProvider } from "./src/utils/contexts/page-context"

export const wrapPageElement = ({
  element,
  props: {
    location: { pathname, search, state },
  },
}: WrapPageElementBrowserArgs<
  unknown,
  Record<string, unknown>,
  LocationState
>) => {
  return (
    <PageProvider pathname={pathname} search={search} state={state}>
      {element}
    </PageProvider>
  )
}
