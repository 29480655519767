import React, { useEffect, useMemo, useState } from "react"

import { isBrowser } from "../browser"
import { LeadRoutes } from "../url-params"

export interface LocationState {
  hubspotOwnerId?: string
  spend?: string
  email?: string
  leadLevel?: LeadRoutes
}

type PageContextType = {
  pathname: string
  search: string
  state: LocationState
}

interface Props {
  children: React.ReactNode
  pathname: string
  search: string
  state: LocationState
}

export const PageContext = React.createContext<PageContextType>({
  pathname: "",
  search: "",
  state: {},
})

export const PageProvider = ({ children, pathname, search, state }: Props) => {
  const value = useMemo(
    () => ({
      pathname,
      search,
      state,
    }),
    [pathname, search, state],
  )

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export const usePageContext = () => {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error("usePageContext must be used within a PageProvider")
  }
  return context
}

export const QUERY_PARAM_CHANGE_EVENT_ID = "queryParamChange" as const

export const useQueryParams = <T extends Record<string, string>>() => {
  type QueryParams = Partial<T>
  const { search } = usePageContext()
  const initialQueryParams = useMemo(() => {
    if (!isBrowser()) return {}
    const urlParams = new URLSearchParams(search)
    return Object.fromEntries(urlParams)
  }, [search]) as QueryParams
  const [queryParams, setQueryParams] =
    useState<QueryParams>(initialQueryParams)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        urlParams.set(key, value)
      } else {
        urlParams.delete(key)
      }
    })
    const stringParams = urlParams.toString()
    const newState =
      window.location.pathname + (stringParams ? `?${stringParams}` : "")
    window.history.pushState({}, "", newState)
  }, [queryParams])

  // Observe changes to the params
  useEffect(() => {
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search)
      setQueryParams(Object.fromEntries(urlParams) as QueryParams)
    }
    document.addEventListener(QUERY_PARAM_CHANGE_EVENT_ID, handlePopState)
    return () => {
      document.removeEventListener(QUERY_PARAM_CHANGE_EVENT_ID, handlePopState)
    }
  }, [])

  return [queryParams, setQueryParams] as const
}

export const useLocationState = (): LocationState => {
  const { state } = usePageContext()
  return useMemo(() => {
    if (!isBrowser()) return {}
    if (!state) return {}
    return state
  }, [state])
}
